<template>
  <div class="certificate-card">
    <div class="d-flex">
      <img
        class="mr-1"
        :src="brand || require('../../assets/images/product-placeholder.svg')"
      />
      <div class="d-flex flex-column justify-content-center ml-2">
        <small>{{ name }}</small>

        <small v-if="isAvailable" class="text-success">
          <i class="fa fa-circle-check text-success" />
          Disponível
        </small>
        <small v-else class="text-danger">
          <i class="fa fa-circle-exclamation text-danger" />
          Disponível ao atingir
          {{ minProgress }}% do curso
        </small>
      </div>
    </div>
    <div>
      <small>Meu progresso</small>

      <div class="d-flex align-items-center">
        <b-progress
          :value="progress"
          class="flex-fill"
          :variant="isAvailable ? 'success' : 'primary'"
        />
        <small class="ml-2">{{ course.course_progress }}</small>
      </div>
    </div>

    <div class="d-flex justify-content-lg-end">
      <button
        v-if="isAvailable"
        class="btn btn-success"
        @click="openCertificate"
      >
        Emitir certificado
        <i class="fa fa-arrow-up-right-from-square" />
      </button>
      <button
        v-else
        class="btn btn-dark"
        @click="goToCourse"
        :disabled="!course.is_enable"
      >
        <template v-if="course.is_enable">
          Ir para o curso
          <i class="fa fa-arrow-right mr-2" />
        </template>
        <template v-else>
          Curso bloqueado
          <i class="fa fa-lock mr-2" />
        </template>
      </button>
    </div>
  </div>
</template>
<script>
import { getToken } from "@/setup/api";
export default {
  props: {
    course: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    let progress = parseInt(
      (this.course?.course_progress || "0").replace("%", "")
    );

    return {
      progress,
      minProgress: this.course?.member_config?.minimum_content_progress || 100,
      name: this.course?.member_config?.name,
      brand:
        this.course?.member_config?.cover_blitz_club ||
        this.course?.member_config?.brand,
      isAvailable: this.course?.certificate_status === "available"
    };
  },
  methods: {
    goToCourse() {
      window.open(
        `${this.course?.member_config?.url_site}/token?token=${getToken()}`
      );
    },
    openCertificate() {
      const hash = this.course?.hash_token;
      const productId = this.course?.product_id;

      hash && productId
        ? window.open(
            `https://api.members.blitzpay.com.br/v1/accounts/students/${hash}/product/${productId}/generate/certificate`,
            "_blank"
          )
        : this.$message.error("Erro ao gerar certificado");
    }
  }
};
</script>
<style lang="scss" scoped>
.certificate-card {
  padding: 16px;
  border-radius: 4px;

  gap: 30px;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr;
  justify-content: space-between;
  @media screen and (min-width: 992px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  img {
    width: 70px;
    aspect-ratio: 1;
    object-fit: cover;
    border-radius: 4px;
    background-color: #c4c0cc;
  }

  .text-success {
    color: #62ad3e !important;
  }

  .progress {
    height: 6px;
    max-width: 400px;
    background-color: #bebfc7;
    ::v-deep {
      .bg-primary {
        background-color: #6b3ead !important;
      }
      .bg-success {
        background-color: #62ad3e !important;
      }
    }
  }

  .btn {
    height: unset;
    min-height: unset;
    padding: 4px 10px;
    border-radius: 50px;
    font-weight: normal;
    border: none;
    i {
      margin-left: 10px;
    }
  }
}
</style>
