<template>
  <div class="view-certificates container-fluid">
    <div class="card mb-5">
      <h6 class="mb-3">Certificados</h6>

      <div v-if="loading" class="text-center my-5">
        <b-spinner />
      </div>

      <h4
        v-else-if="!certificates.length"
        class="text-center font-weight-normal"
      >
        Não foram encontrados certificados
      </h4>

      <template v-else>
        <div class="count">{{ certificates.length }} certificados</div>

        <certificate-card
          v-for="course in certificates"
          :key="course.id"
          class="mt-3"
          :course="course"
        />
      </template>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import CertificateCard from "@/components/certificates/CertificateCard";
export default {
  components: { CertificateCard },
  data() {
    return {
      loading: false
    };
  },
  computed: {
    ...mapState(["myCourses"]),
    certificates() {
      return this.myCourses.filter(
        c => c.certificate_enabled || c.certificate_status
      );
    }
  },
  mounted() {
    this.loading = true;
    this.$store.dispatch("getCourses").finally(() => (this.loading = false));
  }
};
</script>
<style lang="scss" scoped>
.view-certificates {
  .count {
    font-size: 14px;
  }
}
</style>
